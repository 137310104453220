import { SHIFT_STATUS_TYPES } from '@/common/constants/common';
import { Chips } from '@/components/common';
import { cn, getColorForServiceRole } from '@/lib/utils';
import dayjs from '@/lib/dayjsConfig';
import { ServiceRole } from './schedule.interface';

interface Props {
  value: {
    id: string;
    title: string;
    start: string;
    end: string;
    serviceRole: ServiceRole;
    status: SHIFT_STATUS_TYPES;
  };
  onPreview: (id: string) => void;
}

export function ShiftItem({ value, onPreview }: Props) {
  const duration = dayjs(value.end).diff(value.start, 'minute');
  let trimClass;
  if (duration >= 60) {
    trimClass = 'line-clamp-2';
  } else {
    trimClass = 'truncate';
  }

  let colorStatus: string;
  switch (value.status) {
    case SHIFT_STATUS_TYPES.DRAFT:
      colorStatus = 'bg-[#427AA11A] text-[#36617E]';
      break;
    case SHIFT_STATUS_TYPES.CANCELED:
      colorStatus = 'bg-[#CECED61A] text-[#B7B7C1]';
      break;
    case SHIFT_STATUS_TYPES.COMPLETED:
      colorStatus = 'bg-[#1B8A6B1A] text-[#1B8A6B]';
      break;
    case SHIFT_STATUS_TYPES.NO_SHOW:
      colorStatus = 'bg-[#877C7C1A] text-[#877C7C]';
      break;
    case SHIFT_STATUS_TYPES.PENDING:
      colorStatus = 'bg-[#F4743B1A] text-[#DA6A38]';
      break;
    case SHIFT_STATUS_TYPES.SCHEDULED:
      colorStatus = 'bg-[#6794361A] text-[#567B2F]';
      break;
    default:
      colorStatus = 'bg-green-500 text-white';
      break;
  }

  const footerClass = `whitespace-nowrap rounded-b ${colorStatus} px-2 text-xs font-light`;
  return (
    <div
      onClick={() => onPreview(value.id)}
      className={cn(
        'flex h-28 w-full cursor-pointer flex-col justify-between rounded-sm border-2 border-blue-200 bg-white',
        value.status === SHIFT_STATUS_TYPES.NO_SHOW &&
          'bg-[repeating-linear-gradient(-45deg,_#F5F8FF,_#F5F8FF_15px,_#E6EEFF_5px,_#E6EEFF_30px)]'
      )}
    >
      <div className="p-2">
        <p className={cn('mb-2 text-sm font-medium text-blue-950', trimClass)}>{value.title}</p>
        <Chips
          color={getColorForServiceRole(value.serviceRole.title, value.serviceRole.speciality.code)}
          title={value.serviceRole.title}
        />
      </div>
      <p
        className={footerClass}
      >{`${value.start} - ${value.end}`}</p>
    </div>
  );
}
